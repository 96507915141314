export default class LoadMore {
  constructor() {
    this.btnLoadId = "load-more-btn";
    this.btnResetClass = ".load-reset-btn";
    this.loadMoreClass = "load-more";
    this.elementName = "arkon-taxonomy-offer__item";
    this.elementClass = ".arkon-taxonomy-offer__item";
  }

  init = () => {
    document.addEventListener("DOMContentLoaded", this.onReady);
  };

  onReady = () => {
    const button = this.getBtnLoad();
    const resetButton = this.getBtnReset();

    if (button) {
      button.addEventListener("click", () => {
        this.handleClick();
      });
    }

    if (resetButton) {
      resetButton.addEventListener("click", () => {
        this.handleReset();
      });
    }

    const targetElements = this.getElements();
    if (targetElements) {
      for (let i = 0; i < 4 && i < targetElements.length; i++) {
        const element = targetElements[i];
        element.classList.add(this.loadMoreClass);
      }
    }
  };

  getBtnLoad = () => {
    const button = document.getElementById(this.btnLoadId);

    if (!button) {
      console.warn(`getBtnLoad: Invalid button (${this.btnLoadId})`);
      return false;
    }

    return button;
  };

  getBtnReset = () => {
    const resetButton = document.querySelector(this.btnResetClass);

    if (!resetButton) {
      console.warn(`getBtnReset: Invalid button (${this.btnResetClass})`);
      return false;
    }

    return resetButton;
  };

  getElements = () => {
    const targetElements = document.querySelectorAll(this.elementClass);

    if (!targetElements || targetElements.length === 0) {
      console.warn(`getElements: Invalid elements (${this.elementClass})`);
      return false;
    }

    return targetElements;
  };

  handleClick = () => {
    const targetElements = this.getElements();
    const resetButton = this.getBtnReset();

    const filteredElements = Array.from(targetElements).filter(
      (element) => !element.classList.contains(this.loadMoreClass)
    );
    const nextElements = filteredElements.slice(0, 4);

    nextElements.forEach((element) => {
      element.classList.add(this.loadMoreClass);
    });

    if (resetButton) {
      this.showElement(resetButton);
    }

    this.hideButtonIfNeeded();
  };

  hideButtonIfNeeded = () => {
    const targetElements = this.getElements();
    const button = this.getBtnLoad();

    const allElementsLoaded = Array.from(targetElements).every((element) => {
      return (
        element.classList.contains(this.loadMoreClass) &&
        element.classList.contains(this.elementName)
      );
    });

    if (allElementsLoaded) {
      this.hiddenElement(button);
    } else {
      this.showElement(button);
    }
  };

  handleReset = () => {
    const button = this.getBtnLoad();
    const resetButton = this.getBtnReset();
    const targetElements = this.getElements();

    targetElements.forEach((element) => {
      element.classList.remove(this.loadMoreClass);
    });

    if (targetElements) {
      for (let i = 0; i < 4 && i < targetElements.length; i++) {
        const element = targetElements[i];
        element.classList.add(this.loadMoreClass);
      }
    }

    if (resetButton) {
      this.hiddenElement(resetButton);
      this.showElement(button);
    }

    const allElementsLoaded = Array.from(targetElements).every((element) => {
      return (
        element.classList.contains(this.loadMoreClass) &&
        element.classList.contains(this.elementName)
      );
    });

    if (allElementsLoaded) {
      this.hiddenElement(button);
    } else {
      this.showElement(button);
    }
  };

  hiddenElement = (element) => {
    element.classList.add("hidden");
  };

  showElement = (element) => {
    element.classList.remove("hidden");
  };
}
