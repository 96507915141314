export default class CreatePreview {
    constructor() {}

    init() {
        this.preview = document.getElementById('preview-offer');
        this.body = document.querySelector('body');
        this.run();
    }

    run() {
        if (this.isEmptyorUndefined(this.preview)) return;

        this.offers = document.querySelectorAll('.post-offer');
       
        if (this.isEmptyorUndefined(this.offers)) return;

        this.openPreview();
    }

    openPreview() {
        const preview_content = this.preview.querySelector('.preview-content');
        if (this.isEmptyorUndefined(preview_content)) return;
        // const loader = '<div class="loading-post"><svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#959595"> <g fill="none" fill-rule="evenodd"> <g transform="translate(1 1)" stroke-width="2"> <circle stroke-opacity=".5" cx="18" cy="18" r="18"/> <path d="M36 18c0-9.94-8.06-18-18-18"> <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/> </path> </g> </g> </svg></div>'

        const self = this;

        this.offers.forEach(function (offer) {
            offer.addEventListener("click", function (event) {
                self.body.classList.add("loading");
                event.preventDefault();
                const href = event.currentTarget.getAttribute('data-href');
              
                const request = new XMLHttpRequest();
                request.responseType = 'document';
                request.open('GET', href, true);
                request.onload = function () {
                    if (request.status >= 200 && request.status < 400) {
                        // preview_content.innerHTML = loader;
                        const html = request.responseXML.getElementById('post-wrapper').innerHTML;
                        preview_content.innerHTML = html;
                        self.show(self);
                        self.closePreview();
                        self.body.classList.remove("loading");
                    }
                    
                };

                request.send();
                
            });
        });

        
    }

    closePreview() {
        const self = this;

        document.addEventListener("keydown", (event) => {
            if (event.key === 'Escape') {
                self.hide(self);
            }
        });

        const preview_close = this.preview.querySelector('.close');
        if (this.isEmptyorUndefined(preview_close)) return;
        
        preview_close.addEventListener("click", function (event) {
            self.hide(self);
        });
    }

    hide(ind) {
        ind.fadeOut(ind.preview);
        ind.preview.classList.remove("visible");
        ind.body.classList.remove('no-scroll');

        document.dispatchEvent(new Event('arkon-offer-modal-hide'));
    }

    show(ind) {
        ind.preview.classList.add("visible");
        ind.fadeIn(ind.preview);
        ind.body.classList.add('no-scroll');

        document.dispatchEvent(new Event('arkon-offer-modal-show'));
    }

    fadeIn(el){
        el.classList.add('show');
        el.classList.remove('hide');  
    }
      
    fadeOut(el){
        el.classList.add('hide');
        el.classList.remove('show');
    }

    isEmptyorUndefined(el) {
        return el === null || el === undefined || el.length < 1;
    }
}